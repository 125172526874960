import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Grid, Container, Link, styled } from '@mui/material'

import { createPhoneUrl } from 'helpers/createPhoneUrl'
import ContactTypeButton from '@atoms/buttons/ContactTypeButton'
import Button from '@atoms/buttons/Button'
import MainHeading from '@molecules/headings/MainHeading'

// @ts-ignore
import { DatoCmsContactSection } from '__generated__/gatsby-types'

interface Props {
  keyId: string
  contactDetails: DatoCmsContactSection
}

const GridItem = styled(Grid)(({ theme }) => ({
  ['&:last-child']: {
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      borderTop: 'none',
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const ContentSectionContact = ({ keyId, contactDetails }: Props) => {
  const data = useStaticQuery<Queries.ContentSectionContactQuery>(graphql`
    query ContentSectionContact {
      datoCmsContactPage {
        telephoneNumber
        email
      }
    }
  `)

  return (
    <Box sx={{ backgroundColor: 'white', py: { xs: 0, md: 10 } }}>
      <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
        {contactDetails.map((detail: DatoCmsContactSection, index: number) => {
          let url
          switch (detail.contactType.slug) {
            case 'telephone-number':
              url = createPhoneUrl(data?.datoCmsContactPage?.telephoneNumber)
              break
            case 'email':
              url = `mailto:${data?.datoCmsContactPage?.email}`
              break
          }
          return (
            <GridItem
              item
              md={6}
              key={detail.id}
              sx={{
                textAlign: 'center',
                py: 5,
                px: 2,
              }}
            >
              <Container maxWidth="xs">
                <Link href={url}>
                  <ContactTypeButton
                    slug={detail.contactType.slug}
                    ariaLabel={detail.contactType.ariaLabel}
                  />
                </Link>
                <MainHeading
                  typographyProps={{
                    variant: 'h4',
                    component: 'h2',
                    sx: {
                      lineHeight: '1',
                      mb: 0,
                      mt: 2,
                    },
                  }}
                  lineBreaksProps={{
                    text: detail.headingNode.childMarkdownRemark.html,
                    keyId: detail.id,
                    breakpoint: 'lg',
                  }}
                />
                <Box
                  fontSize="1.8rem"
                  lineHeight="1.5"
                  dangerouslySetInnerHTML={{
                    __html: detail.textNode.childMarkdownRemark.html,
                  }}
                />
                <Box>
                  <Button href={url} sx={{ mt: 2, color: 'text.primary' }}>
                    {detail.buttonLabel}
                  </Button>
                </Box>
              </Container>
            </GridItem>
          )
        })}
      </Grid>
    </Box>
  )
}

export default memo(ContentSectionContact)
