// npm
import React, { memo } from 'react'
import {
  Box,
  BoxProps,
  IconButtonProps,
  Typography,
  styled,
} from '@mui/material'
import { Phone, EnvelopeOpen, ShareNetwork } from 'phosphor-react'
// src
import IconButton from '@atoms/buttons/IconButton'

interface Props {
  slug: 'telephone-number' | 'email' | 'social'
  ariaLabel: string
  showHeading?: boolean
  containerProps?: BoxProps
  iconButtonProps?: IconButtonProps
}

const StyledIconButton = styled(IconButton)({
  width: '80px',
  height: '80px',
})

const ContactTypeButton = ({
  slug,
  ariaLabel,
  showHeading,
  containerProps,
  iconButtonProps,
}: Props) => {
  let icon
  switch (slug) {
    case 'telephone-number':
      icon = <Phone size={34} weight="light" />
      break
    case 'email':
      icon = <EnvelopeOpen size={34} weight="light" />
      break
    case 'social':
      icon = <ShareNetwork size={34} weight="light" />
      break
  }

  return (
    <Box textAlign="center" mx="auto" {...containerProps}>
      <StyledIconButton
        color="primary"
        className="primary-light-trans"
        aria-label={ariaLabel}
        {...iconButtonProps}
      >
        {icon}
      </StyledIconButton>
      {showHeading && (
        <>
          <Typography variant="subtitle2" component="h3" sx={{ my: 2 }}>
            {ariaLabel}
          </Typography>
          <Box
            bgcolor="primary.main"
            sx={{
              width: '20px',
              height: '1px',
              lineHeight: '1px',
              mx: 'auto',
            }}
          />
        </>
      )}
    </Box>
  )
}

export default memo(ContactTypeButton)
